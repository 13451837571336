import { Formik } from "formik"
import { useState } from "react"
import * as Yup from "yup"
import { AuthContextType, useAuthContext } from "../../contexts/auth-context"
import { Box } from "@mui/system"
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Stack,
  TextField
} from "@mui/material"
import { ConfirmationForm } from "./ConfirmationForm"
import { MojeButton } from "../../components/ui/MojeButton"
import { MojeTextInput } from "../../components/ui/MojeTextInput"
import { useNavigate } from "react-router-dom"
import { useAuth } from "wakacje-web/src/auth/AuthContext"
import { LinkUtils } from "wakacje-web/src/shared/LinkUtils"
import { PasswordTooltip } from "./PasswordTooltip"
import { useTranslation } from "react-i18next"
import { useTranslatedStatesAndCountries } from "../../hooks/useTranslatedStatesAndCountries"

const STEP_REGISTER = "REGISTER"
const STEP_CONFIRM = "CONFIRM"

export const CompanyRegistrationForm = (props: any) => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const [currentStep, setCurrentStep] = useState(STEP_REGISTER)
  const [signupParams, setSignUpParams] = useState<any>(undefined)
  const [errorCode, setErrorCode] = useState()

  const { listOfUSAStates, listOfEuropeanCountries } =
    useTranslatedStatesAndCountries()

  const { t } = useTranslation()

  const authContext: AuthContextType = useAuthContext()
  const navigate = useNavigate()
  const { user, signup, logout } = useAuth()

  const formSchema = Yup.object({
    type: Yup.string().required(
      t(
        "company_registration_form.error_type_required",
        "Wprowadź rodzaj konta"
      )
    ),
    name: Yup.string().required(
      t("company_registration_form.error_first_name_required", "Wprowadź imię")
    ),
    last_name: Yup.string().required(
      t(
        "company_registration_form.error_last_name_required",
        "Wprowadź nazwisko"
      )
    ),
    email: Yup.string()
      .email(
        t(
          "company_registration_form.error_invalid_email",
          "E-mail nie jest poprawny"
        )
      )
      .required(
        t(
          "company_registration_form.error_email_required",
          "Wprowadź poprawny e-mail"
        )
      ),
    password: Yup.string()
      .required(
        t(
          "company_registration_form.error_password_required",
          "Wprowadź nowe hasło"
        )
      )
      .min(
        8,
        t(
          "company_registration_form.error_password_min_length",
          "Hasło musi mieć minimum 8 znaków, wielką i małą literę oraz cyfrę, a także może zawierać opcjonalne znaki @$!%*#?&"
        )
      )
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
        t(
          "company_registration_form.error_password_requirements",
          "Hasło musi mieć minimum 8 znaków, wielką i małą literę oraz cyfrę, a także może zawierać opcjonalne znaki @$!%*#?&"
        )
      ),
    phone: Yup.string()
      .required(
        t(
          "company_registration_form.error_phone_required",
          "Wprowadź numer telefonu"
        )
      )
      .min(
        8,
        t(
          "company_registration_form.error_invalid_phone",
          "Numer telefonu jest niepoprawny"
        )
      ),
    agree1: Yup.boolean().isTrue(),
    agree2: Yup.boolean().isTrue(),
    passwordCorrect: Yup.boolean().required().isTrue(),
    companyCountryGroup: Yup.string().required(
      t(
        "company_registration_form.error_company_region_required",
        "Wybierz region firmy"
      )
    ),
    language: Yup.string().required(
      t("company_registration_form.error_language_required", "Wybierz język")
    ),
    companyState: Yup.string().when("companyCountryGroup", {
      is: "USA",
      then: Yup.string().required(
        t("company_registration_form.error_state_required", "Wybierz stan")
      ),
      otherwise: Yup.string().notRequired()
    }),
    companyCountry: Yup.string().when("companyCountryGroup", {
      is: "EUROPE",
      then: Yup.string().required(
        t("company_registration_form.error_country_required", "Wybierz kraj")
      ),
      otherwise: Yup.string().notRequired()
    })
  })

  const handleConfirm = async (
    values: any,
    { setSubmitting }: { setSubmitting: Function }
  ) => {
    // console.log("handleConfirm", values)
    // try {
    //   const res = await Auth.confirmSignUp(values.username, values.confirmation)
    //   console.log("Confirmation result", res)
    //   if (res === "SUCCESS") {
    //     // showToastr("Account confirmed!")
    //     //   onConfirmed()
    //   }
    // } catch (error) {
    //   console.log("Confirmation error" + error)
    //   // setConfirmationResult(error)
    // }
  }

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: Function }
  ) => {
    console.log("handleSubmit", currentStep)
    if (currentStep === STEP_CONFIRM) {
      return handleConfirm(values, { setSubmitting })
    }

    try {
      setSubmitting(true)
      setErrorMessage(undefined)
      console.log("call signup")
      var res = await signup(
        values.email.trim(),
        values.password.trim(),
        values.name,
        values.last_name,
        values.phone,
        values.companyCountryGroup,
        values.companyState || values.companyCountry,
        values.language,
        LinkUtils.getAdminHref()
      )
      console.log("Sign upr esult ", res)
      // Save phone to our db
      props.onRegistered(res.user)
      if (res.user && res.user.emailVerified === false) {
        // we got user - redirect to confirmation page
        // setSignUpParams(_signUpParams)
        setCurrentStep(STEP_CONFIRM)
        console.log("Login result")
        // setConfirmationCodeMedium(res.codeDeliveryDetails.DeliveryMedium)
      } else {
        authContext.refetchCurrentUser()
      }
    } catch (error: any) {
      const _errorCode = error.code
      const _errorMessage = error.message
      // https://firebase.google.com/docs/reference/js/auth#autherrorcodes
      console.log("errorCode", _errorCode)
      console.log("Error register", error)
      console.log("Message")
      console.log(error.message)
      setErrorCode(_errorCode)
      console.dir()
      setSubmitting(false)
      if (error.code === "InvalidParameterException") {
        setErrorMessage(
          t(
            "company_registration_form.error_invalid_password",
            "Błędne hasło - nowe hasło musi mieć minimum 8 znaków w tym "
          )
        )
      } else if (
        error.code === "UsernameExistsException" ||
        error.code === "auth/email-already-in-use"
      ) {
        setErrorMessage(
          t(
            "company_registration_form.error_account_exists",
            "To konto już istnieje"
          )
        )
      } else {
        setErrorMessage(error.message)
      }
    }
  }

  const initialValues: any = {
    name: "",
    last_name: "",
    email: "",
    password: "",
    phone: "",
    agree1: false,
    agree2: false,
    type: "organizator",
    companyCountryGroup: props.isUSAVersion ? "USA" : "",
    companyState: "",
    companyCountry: "",
    language: props.isUSAVersion ? "en" : ""
  }

  return (
    <Box>
      <Box>
        {currentStep === STEP_CONFIRM && (
          <ConfirmationForm
            signupParams={signupParams}
            onConfirmed={props.onConfirmed}
          />
        )}
        {currentStep === STEP_REGISTER && (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={formSchema}
            validateOnMount
            validateOnChange
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              submitForm,
              isValid,
              submitCount
            }) => (
              <Stack
                component="form"
                onSubmit={handleSubmit}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    submitForm()
                  }
                }}
              >
                <MojeTextInput
                  valid={values.name && !errors.name}
                  label={t("company_registration_form.first_name", "Imię")}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  name="name"
                  required
                  errors={errors}
                  touched={touched}
                />

                <MojeTextInput
                  valid={values.last_name && !errors.last_name}
                  label={t("company_registration_form.last_name", "Nazwisko")}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  name="last_name"
                  required
                  errors={errors}
                  touched={touched}
                />
                <MojeTextInput
                  valid={values.email && !errors.email}
                  label={t("company_registration_form.email", "E-mail")}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  name="email"
                  required
                  errors={errors}
                  touched={touched}
                />

                <MojeTextInput
                  valid={values.phone && !errors.phone}
                  label={t("company_registration_form.phone", "Telefon")}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  name="phone"
                  required
                  errors={errors}
                  touched={touched}
                />
                <div className="mt-6">
                  <TextField
                    select
                    label={t(
                      "company_registration_form.company_region",
                      "Region firmy"
                    )}
                    variant="outlined"
                    className="bg-white"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    name="companyCountryGroup"
                    required
                    value={values.companyCountryGroup}
                  >
                    <MenuItem value="USA">
                      {t("company_registration_form.region_usa", "USA")}
                    </MenuItem>
                    <MenuItem value="EUROPE">
                      {t("company_registration_form.region_europe", "Europa")}
                    </MenuItem>
                  </TextField>
                </div>

                {values.companyCountryGroup === "USA" && (
                  <div className="mt-6">
                    <TextField
                      select
                      label={t("company_registration_form.state", "Stan")}
                      variant="outlined"
                      className="bg-white"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      name="companyState"
                      required
                      value={values.companyState}
                    >
                      {listOfUSAStates.map((state) => (
                        <MenuItem key={state.value} value={state.value}>
                          {state.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}

                {values.companyCountryGroup === "EUROPE" && (
                  <div className="mt-6">
                    <TextField
                      select
                      label={t("company_registration_form.country", "Kraj")}
                      variant="outlined"
                      className="bg-white"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      name="companyCountry"
                      required
                      value={values.companyCountry}
                    >
                      {listOfEuropeanCountries.map((country) => (
                        <MenuItem key={country.value} value={country.value}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}

                <div className="mt-6">
                  <TextField
                    select
                    label={t("company_registration_form.language", "Język")}
                    variant="outlined"
                    className="bg-white"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    name="language"
                    required
                    value={values.language}
                  >
                    <MenuItem value="pl">
                      {t("company_registration_form.language_polish", "Polski")}
                    </MenuItem>
                    <MenuItem value="en">
                      {t(
                        "company_registration_form.language_english",
                        "Angielski"
                      )}
                    </MenuItem>
                  </TextField>
                </div>

                {currentStep === STEP_REGISTER && (
                  <PasswordTooltip setFieldValue={setFieldValue}>
                    <MojeTextInput
                      label={t("company_registration_form.password", "Hasło")}
                      variant="outlined"
                      size="small"
                      margin="normal"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      name="password"
                      required
                      errors={{ ...errors, passwordCorrect: {} }}
                      touched={touched}
                      valid={
                        values.password &&
                        !errors.password &&
                        !errors.passwordCorrect
                      }
                      passwordField
                    />
                  </PasswordTooltip>
                )}
                <div className="mt-5"></div>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <div className="custom-checkbox">
                        <Checkbox
                          sx={{
                            alignSelf: "start",
                            fontSize: 0.75
                          }}
                          name="agree1"
                          checked={values.agree1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>
                    }
                    className="text-white"
                    label={
                      <div className="text-sm">
                        {t(
                          "company_registration_form.agreement1",
                          "Wyrażam zgodę na przetwarzanie danych osobowych zawartych w karcie kwalifikacyjnej na potrzeby niezbędne do zapewnienia bezpieczeństwa i ochrony zdrowia uczestnika wypoczynku (zgodnie z ustawą z dnia 29 sierpnia 1997 r. o ochronie danych osobowych (Dz. U. z 2015 r. poz. 2135, z późn. zm.))."
                        )}
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <div className="custom-checkbox">
                        <Checkbox
                          sx={{ fontSize: 0.75 }}
                          name="agree2"
                          checked={values.agree2}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    }
                    className="text-white"
                    label={
                      <div className="text-sm">
                        {t(
                          "company_registration_form.agreement2_part1",
                          "Klikając przycisk poniżej akceptujesz "
                        )}
                        <a
                          href={`https://admin.${process.env.NEXT_PUBLIC_DOMAIN}/regulamin`}
                          target="_blank"
                          className="underline cursor-pointer text-[color:var(--fc-color-primary-blue)] "
                          rel="noreferrer"
                        >
                          <span className="">
                            {" "}
                            {t(
                              "company_registration_form.regulations",
                              "Regulamin"
                            )}
                          </span>
                        </a>
                      </div>
                    }
                  />
                </FormGroup>

                <div className="w-full mt-5">
                  <MojeButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    label={t(
                      "company_registration_form.create_account",
                      "Załóż konto"
                    )}
                    isSaving={isSubmitting}
                    disabled={!isValid}
                  />
                </div>

                {/* {this.renderLoginWithFacebook()} */}
                {/* <div className="renderLoginWithFacebook" */}
                {/* <div className="col-md-6">
                        <MpsButton
                          type="button"
                          onClick={async () => {
                            await setFieldValue('mode', 'signup', false)
                            submitForm()
                          }}
                          className="btn btn-tool btn-primary"
                          tabIndex="50"
                          style={{
                            width: '100%',
                            borderRadius: 4,
                            backgroundColor: '#ff8204',
                            color: 'white'
                          }}
                          label="Sign Up"
                        />
                      </div> */}
                {/* {JSON.stringify(values)} */}

                {errorMessage && (
                  <div
                    style={{
                      textAlign: "center",
                      padding: 10,
                      height: 30,
                      color: "red"
                    }}
                  >
                    {errorMessage}
                  </div>
                )}
                <div className="py-5">
                  <p
                    className="text-white text-center cursor-pointer"
                    onClick={() => navigate("/login")}
                  >
                    {t(
                      "company_registration_form.already_have_account",
                      "Mam już konto"
                    )}
                  </p>
                </div>
              </Stack>
            )}
          </Formik>
        )}
      </Box>
    </Box>
  )
}
