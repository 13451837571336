import React, { createContext, useState, useEffect } from "react"

const CURRENCY_LIST = [
  { currency: "PLN", name: "Polish Zloty", symbol: "ZŁ" },
  { currency: "USD", name: "US Dollar", symbol: "$" }
  // Add more currencies as needed
]

export const CurrencyContext = createContext(null)

export const CurrencyProvider = ({ children }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(CURRENCY_LIST[0])

  // Load the saved currency from localStorage on component mount
  useEffect(() => {
    const savedCurrency = JSON.parse(localStorage.getItem("selectedCurrency"))
    if (savedCurrency) {
      setSelectedCurrency(savedCurrency)
    }
  }, [])

  // Save the selected currency to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("selectedCurrency", JSON.stringify(selectedCurrency))
  }, [selectedCurrency])

  const changeCurrency = (currency) => {
    setSelectedCurrency(currency)
  }

  return (
    <CurrencyContext.Provider
      value={{
        selectedCurrency: selectedCurrency
          ? selectedCurrency
          : { currency: "USD", name: "US Dollar", symbol: "$" },
        changeCurrency,
        CURRENCY_LIST
      }}
    >
      {children}
    </CurrencyContext.Provider>
  )
}
