import React, { useState } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { FormHelperText, Heading3 } from "../FormCommons"
import { FieldError } from "../FieldError"
import { useTranslation } from "react-i18next"
import SearchIcon from "../icons/SearchIcon"
import CloseIcon from "../icons/CloseIcon"
import { Tag } from "../Tag/Tag"
import { useParticipantLanguages } from "../../hooks/useParticipantLanguages"

const ParticipantLanguages = ({ register, values, setValue, errors }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedLanguages, setSelectedLanguages] = useState(
    values?.participants_languages || []
  )

  const participantsLanguages = useParticipantLanguages()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleLanguageToggle = (languageId) => {
    if (selectedLanguages.includes(languageId)) {
      setSelectedLanguages(selectedLanguages.filter((id) => id !== languageId))
    } else {
      setSelectedLanguages([...selectedLanguages, languageId])
    }
  }

  const handleDeleteChip = (languageToDelete) => {
    setSelectedLanguages((languages) =>
      languages.filter((languageId) => languageId !== languageToDelete)
    )
  }

  const filteredLanguages = participantsLanguages.filter((language) =>
    language.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const handleClearSelection = () => {
    setSelectedLanguages([])
  }

  return (
    <>
      <Heading3 id="h3-1" className="mb-4">
        {t("product.participants_languages", "Języki uczestników")}
      </Heading3>

      <div className="mb-4">
        <FormHelperText>
          {t(
            "product.participants_languages_description",
            "Języki, którymi organizator może porozumiewać się z uczestnikami"
          )}
        </FormHelperText>
      </div>

      {selectedLanguages.length > 0 && (
        <div className="flex gap-3 mb-6 flex-wrap">
          {selectedLanguages.map((selectedLanguageId, index) => {
            const language = participantsLanguages.find(
              (lang) => lang.id === selectedLanguageId
            )
            if (!language) {
              return null
            }
            return (
              <Tag
                key={index}
                label={language.name}
                onClick={() => handleDeleteChip(selectedLanguageId)}
              />
            )
          })}
        </div>
      )}

      <div className="mb-4 mt-8 text-center">
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
          size="large"
        >
          <p className="text-base">
            {t("product.add_participants_language", "Dodaj języki uczestnika")}
          </p>
        </Button>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <div className="flex pt-6 pl-5 pr-5 justify-end">
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="relative pb-0 mb-0">
          <DialogTitle className="text-center text-[#042675] font-bold uppercase">
            <p className="font-bold text-lg">
              {t("product.participant_languages", "Języki uczestnika")}
            </p>
          </DialogTitle>
        </div>
        <DialogContent>
          <div className="mb-4">
            {selectedLanguages.length > 0 && (
              <div className="mb-4 flex flex-wrap gap-2">
                {selectedLanguages.map((languageId) => {
                  const language = participantsLanguages.find(
                    (lang) => lang.id === languageId
                  )
                  return (
                    <Button
                      key={languageId}
                      variant="outlined"
                      size="small"
                      onClick={() => handleLanguageToggle(languageId)}
                      className="text-[#042675]"
                      style={{
                        borderRadius: "12px"
                      }}
                    >
                      {`${language?.name} ×`}
                    </Button>
                  )
                })}
              </div>
            )}
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t("search", "Szukaj...")}
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: "12px"
                }
              }}
            />
          </div>
          <div className="ml-2 max-h-64 overflow-auto">
            <div className="grid gap-2">
              {filteredLanguages.map((language) => (
                <FormControlLabel
                  key={language.id}
                  control={
                    <Checkbox
                      checked={selectedLanguages.includes(language.id)}
                      onChange={() => handleLanguageToggle(language.id)}
                    />
                  }
                  label={language.name}
                />
              ))}
            </div>
          </div>
        </DialogContent>
        <div className="flex justify-between p-5 mb-3">
          <Button onClick={handleClearSelection} size="large">
            <p className="text-base">{t("clear", "Wyczyść")}</p>
          </Button>
          <Button
            onClick={() => {
              setValue("participants_languages", selectedLanguages)
              handleClose()
            }}
            variant="contained"
            size="large"
          >
            <p className="text-base">{t("select", "Wybierz")}</p>
          </Button>
        </div>
      </Dialog>
    </>
  )
}

export default ParticipantLanguages
