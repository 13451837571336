import axios from "axios"
import { SnackbarProvider } from "notistack"
import React, { Suspense } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import App from "./App"
import MuiGlobalTheme from "./mui-theme"
import reportWebVitals from "./reportWebVitals"
import { FCAuthContextProvider } from "./contexts/auth-context"
import { WakacjeTheme } from "./wakacje-theme"
import { IntlProvider } from "react-intl"
import "./i18n"
import { AuthContextProvider } from "wakacje-web/src/auth/AuthContext"
import { auth } from "wakacje-web/config/firebase"
import { createRoot } from "react-dom/client"

// Use common name across web and admin
var w = window as any
w.fajnyczas_api_url = process.env.REACT_APP_WAKACJE_API_URL
axios.interceptors.request.use(async (config) => {
  try {
    if (auth.currentUser) {
      var token = await auth.currentUser.getIdToken()
      config.headers.Authorization = "Bearer " + token
      return config
    }
  } catch (err) {
    console.log("Firebase auth get token err", err)
  }
  return config
})
const root = createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    {/* <TwinGlobalStyles /> */}
    <WakacjeTheme />
    <HelmetProvider>
      <IntlProvider locale="pl" defaultLocale="pl">
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <AuthContextProvider
            type="MERCHANT"
            client={`admin.${process.env.NEXT_PUBLIC_DOMAIN}`}
          >
            <FCAuthContextProvider>
              <MuiGlobalTheme>
                <Suspense fallback={<div>Loading... </div>}>
                  <App />
                </Suspense>
              </MuiGlobalTheme>
              <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                  rel="preconnect"
                  href="https://fonts.gstatic.com"
                  crossOrigin={undefined}
                />
                <link
                  href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                  rel="stylesheet"
                />
              </Helmet>
            </FCAuthContextProvider>
          </AuthContextProvider>
        </SnackbarProvider>
      </IntlProvider>
    </HelmetProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
