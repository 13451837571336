import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  OutlinedInput,
  InputLabel,
  FormControl
} from '@mui/material'
import { Box } from '@mui/system'
import {
  FieldPath,
  FormProvider,
  RegisterOptions,
  useForm,
  get,
  Controller
} from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router'
import { InputCheck, InputField } from '../../../components/FormCommons'
import { useArticle, useArticleApi } from './useArticlesApi'
import { useCallback, useEffect, useState } from 'react'
import {
  buildPhoto,
  UploadablePhoto
} from '../../organizer/products/forms/ProductSectionPhotos'
import { SavingButton } from '../../../components/commons/SavingButton'
import CustomEditor from './CustomEditor'
import { useCategories } from '../../../components/useCategories'
import { useNavigate } from 'react-router-dom'
import slugify from 'slugify'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useTranslation } from 'react-i18next'

const tags = ['Tag 1', 'Tag 2', 'Tag 3']

const WEB_URL = process.env.WEB_URL
export const ArticlePage = () => {
  const params = useParams<any>()
  const { loading, data, refetch } = useArticle(params.id as string)
  return (
    <>
      {loading && <LinearProgress />}
      {data && (
        <ArticleForm article={data} refetch={refetch} type={data.type} />
      )}
    </>
  )
}

export const ArticleForm = ({
  article,
  refetch,
  type
}: {
  article?: any
  refetch?: Function
  type: any
}) => {
  const navigate = useNavigate()
  const { t: t1 } = useTranslation('common')
  const { categories } = useCategories(t1)

  const [seoCategories, setSeoCategories] = useState([])
  const { updateArticle, createArticle, runCommand, listArticles } =
    useArticleApi()
  const { enqueueSnackbar } = useSnackbar()
  const [hasDeleteAlert, setHasDeleteAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [slug, setSlug] = useState('')
  const [slugNoPrefix, setSlugNoPrefix] = useState('')
  const [slugEn, setSlugEn] = useState('')
  const [slugEnNoPrefix, setSlugEnNoPrefix] = useState('')
  const [tag, setTags] = useState<string[]>([])

  // const slugUrlPrefix = WEB_URL + (type==='blog' ? 'p/' : '');
  // const slugUrlPrefix = 'https://www.fajnyczas.pl'+ (type==='blog' ? '/blog/' : '/p/');
  const slugUrlPrefix =
    'https://www.gofunlo.com' + (type === 'blog' ? '/blog/' : '/p/')
  // console.log('WEB_URL:', WEB_URL);
  const handleChange = (event: SelectChangeEvent<typeof tag>) => {
    const {
      target: { value }
    } = event
    setTags(typeof value === 'string' ? value.split(',') : value)
  }

  useEffect(() => {
    loadSeoCategories()
  }, [])
  const loadSeoCategories = useCallback(async () => {
    // const seoData= listSeoCategories()?.data;
    // setLoading(true)
    try {
      const result = await listArticles({ type: 'seo' }, false)
      console.log('r: ', result)
      setSeoCategories(result)
      // setLoading(false)
    } catch (err) {
      console.error('Erro ', err)
      // setLoading(false)
    }
  }, [])

  const [htmlContent, setHtmlContent] = useState(article?.content_text ?? '')
  const [htmlContentEn, setHtmlContentEn] = useState(
    article?.content_text_en ?? ''
  )

  const defaultValues: any = article
    ? {
        ...article,
        type: type
      }
    : {
        type: type
      }
  if (article?.published_at) {
    // defaultValues.published_at = parseISO(article.published_at)
  }
  //   defaultValues.content = article?.contentRaw
  //     ? EditorState.createWithContent(convertFromRaw(article.contentRaw))
  //     : EditorState.createEmpty()
  const formMethods = useForm<any>({
    defaultValues
  })
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = formMethods

  const register = (name: FieldPath<any>, options?: RegisterOptions) => ({
    ...formMethods.register(name, { ...options }),
    id: name,
    error: Boolean(get(errors, name, false))
  })

  const handleDelete = async () => {
    const command = {
      type: 'Article.Delete',
      aggregateId: article.id
    }
    // console.log("command", command)
    try {
      await runCommand(command)
      enqueueSnackbar('Artykuł usunięty')
      setHasDeleteAlert(false)
      refetch()
      navigate('/super/articles')
    } catch (err) {
      enqueueSnackbar('Problem z usunięciem artykułu')
    }
  }

  async function onFormSubmit (rawData: any) {
    console.log('&&&&&&&&&&&&&onformsubmit input raw', rawData)
    // const contentHTML = convertToHTML({
    //   entityToHTML: (entity, originalText) => {
    //     console.log("entity to html", entity, originalText)
    //     if (entity.type === "IMAGE") {
    //       var dataObj = Object.keys(entity.data).reduce((a: any, i: any) => {
    //         a["data-" + i] = entity.data[i]
    //         return a
    //       }, {})
    //       return <img src={entity.data.src} {...dataObj} alt="Zdjecie"></img>
    //     }
    //     return originalText
    //   },
    // })(content.getCurrentContent())

    // const article = {
    //   ...rawData,
    //   content_text: contentHTML,
    // }

    setLoading(true)

    const article = {
      ...rawData,
      tags: tag,
      parent_id: rawData['parent_id'] === '' ? null : rawData['parent_id'],
      content_text: htmlContent,
      slug: slugNoPrefix,
      context_text_en: htmlContentEn,
      slug_en: slugEnNoPrefix
    }
    if (article.published_at === '') {
      article.published_at = null
    }
    console.log('aaaaaaaaaaaaaaarticle saved: ', article)

    await (article.id ? updateArticle(article) : createArticle(article))

    enqueueSnackbar('Zapisano', { variant: 'success' })
    setLoading(false)
    navigate('/super/articles')
  }

  // const type = formMethods.watch("type")
  const category_id = formMethods.watch('category_id')
  const parent_id = formMethods.watch('parent_id')
  const main_photo = formMethods.watch('main_photo')

  const handleMainPhotoUploaded = (newPhoto: any) => {
    formMethods.setValue('main_photo', buildPhoto(newPhoto))
    return true
  }

  return (
    <Box sx={{ maxWidth: 672 }}>
      <h2 className='text-lg p-3 font-bold bg-sky-50 my-5'>
        {type === 'blog' ? 'Artykuł' : 'Seo'}
      </h2>
      <Box>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            {type === 'seo' && (
              <>
                <InputField label='Link' {...register('link')} />
                <InputField
                  label='Umieść pod'
                  {...register('parent_id')}
                  value={parent_id}
                  select
                >
                  <MenuItem value=''>Wybierz nadrzedny wpis</MenuItem>
                  {seoCategories.map(c => (
                    <MenuItem value={c.id} key={c.id}>
                      {c.title}
                    </MenuItem>
                  ))}
                </InputField>
              </>
            )}

            <Controller
              name={'title'}
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => {
                setSlug(
                  slugUrlPrefix + slugify(value ? value : '', { lower: true })
                )
                setSlugNoPrefix(slugify(value ? value : '', { lower: true }))
                return (
                  <InputField
                    label='Tytuł'
                    {...register('title')}
                    onChange={onChange}
                  />
                )
              }}
            />

            <InputField
              value={slug}
              label='Slug (Adres)'
              {...register('slug')}
            />

            {type === 'seo' && (
              <>
                <InputField
                  label='Meta'
                  {...register('meta')}
                  multiline
                  minRows={4}
                />
                <InputField
                  label='Description'
                  {...register('summary')}
                  multiline
                  minRows={4}
                />
                <InputField
                  label='Position'
                  {...register('position_in_tree')}
                  minRows={4}
                  type='number'
                />
              </>
            )}

            {type === 'blog' && (
              <>
                <InputField
                  label='Podsumowanie'
                  {...register('summary')}
                  multiline
                  minRows={4}
                />
                <InputField label='Autor' {...register('author_name')} />
                <div className='mb-2'>
                  <div className='h-[288px] w-[672px] bg-gray-100 my-4 '>
                    <UploadablePhoto
                      photo={main_photo}
                      onPhotoUploaded={handleMainPhotoUploaded}
                      label='Dodaj zdjęcie główne'
                    />
                  </div>
                </div>
              </>
            )}
            <CustomEditor
              value={htmlContent}
              onChange={data => {
                setHtmlContent(data)
              }}
            />

            {article?.deleted_at ? (
              <div className='text-center text-xl text-red-500 font-bold'>
                Ten artykuł został usunięty {article.deleted_at}
              </div>
            ) : (
              <Controller
                name={'published_at'}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => {
                  var val =
                    value != null && value.length > 10
                      ? value.substr(0, 10)
                      : value
                  return (
                    <InputField
                      type='date'
                      onChange={onChange}
                      value={val}
                      InputLabelProps={{ shrink: true }}
                      label='Data publikacji'
                      id={`published_at`}
                    />
                  )
                }}
              />
            )}
            {type === 'seo' && (
              <FormControl sx={{ mt: 4, width: 1 / 3 }}>
                <InputLabel>Tag</InputLabel>
                <Select
                  fullWidth
                  multiple
                  value={tag}
                  {...register('tags')}
                  input={<OutlinedInput label='Tag' />}
                  renderValue={selected => selected.join(', ')}
                  onChange={handleChange}
                >
                  {tags.map(name => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={tag.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {type === 'seo' && (
              <>
                <div className='w-1/3'>
                  <InputCheck label='Aktywny' {...register('active')} />
                </div>

                <div className='w-1/3'>
                  <InputCheck
                    label='Pokazuj w nawigacji'
                    {...register('nav')}
                  />
                </div>
              </>
            )}
            {type === 'seo' && (
              <>
                <InputField label='Link' {...register('link')} />
                <InputField
                  label='Umieść pod'
                  {...register('parent_id')}
                  value={parent_id}
                  select
                >
                  <MenuItem value=''>Wybierz nadrzedny wpis</MenuItem>
                  {seoCategories.map(c => (
                    <MenuItem value={c.id} key={c.id}>
                      {c.title}
                    </MenuItem>
                  ))}
                </InputField>
              </>
            )}

            <Controller
              name={'title_en'}
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => {
                setSlugEn(
                  slugUrlPrefix + slugify(value ? value : '', { lower: true })
                )
                setSlugEnNoPrefix(slugify(value ? value : '', { lower: true }))
                return (
                  <InputField
                    label='Tytuł angielski'
                    {...register('title_en')}
                    onChange={onChange}
                  />
                )
              }}
            />

            <InputField
              value={slugEn}
              label='Slug angielski(Adres)'
              {...register('slug_en')}
            />

            {type === 'blog' && (
              <>
                <InputField
                  label='Podsumowanie angielskie'
                  {...register('summary_en')}
                  multiline
                  minRows={4}
                />
              </>
            )}
            <CustomEditor
              value={htmlContentEn}
              onChange={data => {
                setHtmlContentEn(data)
              }}
            />
            <Box display='flex' my={2}>
              <Box>
                <Button variant='text' color='secondary'>
                  Anuluj
                </Button>
              </Box>
              {article && (
                <div>
                  <Button
                    variant='text'
                    color='secondary'
                    onClick={() => setHasDeleteAlert(true)}
                  >
                    Usuń artykuł
                  </Button>
                </div>
              )}
              <Box flex={1} />
              <SavingButton
                variant='contained'
                type='submit'
                label={
                  loading ? (
                    <CircularProgress size={20} color='info' />
                  ) : (
                    'Zapisz'
                  )
                }
              ></SavingButton>
            </Box>
          </form>
        </FormProvider>
      </Box>

      <Dialog
        open={hasDeleteAlert}
        onClose={() => setHasDeleteAlert(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Czy na pewno usunąć artykuł?'}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHasDeleteAlert(false)}>Anuluj</Button>
          <Button onClick={handleDelete} autoFocus>
            Tak
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
